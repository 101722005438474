import { useTranslation } from 'next-i18next';

const useTranslateUrl = () => {
  const { t } = useTranslation('fe_er_deal_card');

  const translateUrl = (url) => {
    const splitedUrl = url.split('/');
    let translatedUrl = '';
    splitedUrl.forEach((segment, index) => {
      if (index > 0) {
        const translatedSegemnt = t(`routes:${segment}`);
        translatedUrl += `/${translatedSegemnt || ''}`;
      }
    });
    return translatedUrl;
  };
  return translateUrl;
};

export default useTranslateUrl;
