export const localeDateFormatMap = {
  en: 'MM/DD/YYYY',
  'en-CA': 'DD/MM/YYYY',
  'en-GB': 'DD/MM/YYYY',
  'en-AU': 'DD/MM/YYYY',
  cn: 'YYYY/MM/DD',
  ja: 'YYYY年MM月DD',
  motorcykeludlejninger: 'DD-MM-YYYY',
  motorsykkelleie: 'DD.MM.YYYY',
  sv: 'YYYY-MM-DD',
  ko: 'YYYY.MM.DD',
  ar: 'DD/MM/YYYY',
  ru: 'DD.MM.YYYY',
  'aluguel-de-motos': 'DD/MM/YYYY',
  'noleggio-moto': 'DD/MM/YYYY',
  motorverhuur: 'DD-MM-YYYY',
  'alquiler-de-motos': 'DD/MM/YYYY',
  'location-moto': 'DD/MM/YYYY',
  'motorrad-mieten': 'DD.MM.YYYY',
  zh: 'YYYY/MM/DD',
};

export const localeDateMaskMap = {
  en: '__/__/____',
  'en-CA': '__/__/____',
  'en-GB': '__/__/____',
  'en-AU': '__/__/____',
  cn: '____/__/__',
  ja: '____年__月__',
  motorcykeludlejninger: '__-__-____',
  motorsykkelleie: '__.__.____',
  sv: '____-__-__',
  ko: '____.__.__',
  ar: '__/__/____',
  ru: '__.__.____',
  'aluguel-de-motos': '__/__/____',
  'noleggio-moto': '__/__/____',
  motorverhuur: '__-__-____',
  'alquiler-de-motos': '__/__/____',
  'location-moto': '__/__/____',
  'motorrad-mieten': '__.__.____',
  zh: '____/__/__',
};
