import { useMemo } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

const useMultiSizedImage = (imageObj) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isCrawler = useSelector((state) => state.globalData.isCrawler);
  const image = useMemo(() => {
    if (isMobile || isCrawler) {
      return imageObj.small_url;
    }
    if (isTablet) {
      return imageObj.medium_url;
    }
    if (isLargeScreen) {
      return imageObj.large_url;
    }
    return imageObj.xlarge_url || imageObj.large_url;
  }, [imageObj, isTablet, isMobile, isLargeScreen]);
  return image;
};

export default useMultiSizedImage;
