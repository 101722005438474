import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ImgBox } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .PMBSCard': {
    border: `1px solid ${theme.palette.grey[100]}`,
    background: theme.palette.common.white,
    height: '100%',
    padding: theme.spacing(2, 0),
  },
  '& .PMBSBikeInfo': {
    margin: theme.spacing(1, 2, 0, 2),
  },
  '& .PMBSModelName': {
    marginBottom: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
  '& .PMBSBikeImgContainer': {
    width: '100%',
    height: theme.spacing(22.90325),
  },
  '& .PMBSLinkContainer': {
    padding: theme.spacing(0.5, 0),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    '&:last-child': {
      borderBottom: 0,
    },
  },
  '& .PMBSLink': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.secondary.light,
  },
  '& .PMBSLinkIcon': {
    color: theme.palette.grey[200],
    fontSize: theme.spacing(2.5),
  },
  '& .CarouselItem': {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(0.25),
    },
  },
}));
export const StyledImgBox = styled(ImgBox)(() => ({
  '& .IBimg': {
    objectPosition: 'left',
  },
}));

export default StyledBox;
