export const DEAL_TYPES = {
  DAILY_RATE: 'daily_rate',
  ONE_WAY_SPECIAL: 'one_way_special',
  BUY_ONE_DAY_GET_ONE: 'buy_one_day_get_one',
  DISCOUNT_AMOUNT: 'discount_amount',
  TOUR: 'tour',
  DISCOUNT_PERCENT: 'discount_percent',
  PACKAGE_PRICE: 'package_price',
};

export const PAGES_GA_IDENTIFIERS = {
  DEALS_LISTING: 'Deals Listing Page',
  DEAL: 'Deal Page',
  GEO: 'Geo Page',
  HOME: 'Home Page',
  LOCATION: 'Location Page',
  EVENTS_LISTING: 'Events Listing Page',
  EVENT: 'Event Page',
  TOURS_LANDING: 'Tours Landing Page',
  TOURS_LISTING: 'Tours Listing Page',
  TOURS_DETAILS: 'Tour Details Page',
  LOCATIONS: 'Locations Page',
  EXPERIENCE_LANDING: 'Experience Landing Page',
  EXPERIENCE_LISTING: 'Experience Listing Page',
  OFF_ROAD_LANDING: 'Offroad Landing Page',
  TOUR: 'Tour Details Page',
};
