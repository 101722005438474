import React, { useEffect, useRef } from 'react';

import Typography500 from 'views/common/components/UI/Typography500';
import { StyledBox } from 'views/common/components/UI/PromotionBanner/PromotionBanner.styles';
import { Box } from '@mui/material';

import { useSelector } from 'react-redux';
import useAppNavigator from 'views/common/hooks/useAppNavigator';

const PromotionBanner = (props) => {
  const { bannersList, pageName } = props;
  const containerRef = useRef(null);
  const { deviceType } = useSelector((state) => state.globalData);
  const appNavigator = useAppNavigator();

  const handleLinkClick = (event) => {
    // Prevent the default link behavior (optional)
    event.preventDefault();

    // Get the URL of the clicked link
    const url = event.target.href;

    // Perform your custom logic here
    window.gtag('event', 'promo_banner', {
      Screen: pageName,
      Device: deviceType,
      'Banner Id': bannersList[0].id,
    });

    // Optionally, you can redirect to the link after your custom logic
    appNavigator.push(url);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const links = container.querySelectorAll('a');
      links.forEach((link) => {
        link.addEventListener('click', handleLinkClick);
      });
    }

    // Cleanup function to remove event listeners when the component unmounts
    return () => {
      if (container) {
        const links = container.querySelectorAll('a');
        links.forEach((link) => {
          link.removeEventListener('click', handleLinkClick);
        });
      }
    };
  }, []);

  return (
    <Box ref={containerRef}>
      {bannersList[0].component ? (
        bannersList[0].component
      ) : (
        <StyledBox>
          <Typography500
            variant="h5"
            component="div"
            dangerouslySetInnerHTML={{
              __html: bannersList[0].text,
            }}
          />
        </StyledBox>
      )}
    </Box>
  );
};
export { PromotionBanner };
