import React from 'react';
import { Skeleton, Container } from '@mui/material';

const AboutUsSectionSkeleton = () => (
  <Container maxWidth="xl">
    <Skeleton variant="text" width="20%" height={60} />
    <Skeleton variant="text" width="100%" height={40} />
    <Skeleton variant="text" width="100%" height={40} />
    <Skeleton variant="text" width="100%" height={40} />
    <Skeleton variant="text" width="100%" height={40} />
  </Container>
);
export { AboutUsSectionSkeleton };
