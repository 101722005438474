import React from 'react';
import {
  Box,
  Grid,
  styled,
  Skeleton,
  useTheme,
  useMediaQuery,
} from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .PMBSectionWrapper': {
    padding: theme.spacing(4, 0),
    display: 'flex',
    '& .PMBSBtn': {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
        order: 1,
      },
    },
    '& .PMBSTitle': {
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },
}));
const PopularMotorcycleBrandsSectionSkeleton = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const getnumber = () => {
    let n;
    if (isTablet && !isMobile) {
      n = 2;
    } else if (isTablet && isMobile) {
      n = 1;
    } else {
      n = 4;
    }
    return n;
  };

  return (
    <StyledBox>
      <Box className="PMBSectionWrapper">
        <Grid container rowSpacing={2} columnSpacing={{ xs: 3 }}>
          <Grid item xs={12} lg={6} className="PMBSTitle">
            <Skeleton variant="rectangular" width="40%" height={40} />
          </Grid>
          <Grid item xs={12} lg={6} className="PMBSBtn">
            <Skeleton variant="rectangular" width="40%" height={40} />
          </Grid>
          {[...Array(getnumber()).keys()].map((item) => (
            <Grid item xs={12} md={6} lg={4} xl={3} key={item}>
              <Skeleton variant="rectangular" width="100%" height={300} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </StyledBox>
  );
};
export { PopularMotorcycleBrandsSectionSkeleton };
