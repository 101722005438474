import React from 'react';
import StyledBox, {
  StyledImgBox,
} from 'views/HomeView/components/PopularMotorcycleBrandsSection/PopularMotorcycleBrandsSection.styles';
import { useTranslation } from 'next-i18next';
import Typography500 from 'views/common/components/UI/Typography500';
import { Typography700 } from 'views/common/components';
import { Box } from '@mui/material';
import { StyledSliderSectionContiner } from 'views/HomeView/components/ContinueWhereULeftOff/ContinueWhereULeftOff.styles';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PATHS from 'lib/navigation/paths';

const PopularMotorcycleBrandsSection = (props) => {
  const { t } = useTranslation('fe_er_home_page');
  const { promotedVehicleMakes } = props;
  return promotedVehicleMakes.length > 0 ? (
    <StyledBox>
      <StyledSliderSectionContiner
        title={t('fe_er_home_page:popular_motorcycle_brands')}
        component="h2"
        buttons={[
          {
            text: t('fe_er_home_page:more_brands'),
            path: PATHS.motorcycleRental({ ref: 'homepage_vehicles' }),
          },
        ]}
        largedesktopItems={4}
        deskTopItems={3}
        carouselArrow>
        {promotedVehicleMakes.map((item) => (
          <Box className="CarouselItem" key={item.id}>
            <Box className="PMBSCard">
              <NavigatorLink
                href={PATHS.geoSlug([item.slug], { ref: 'homepage_vehicles' })}
                translate={false}>
                <Box className="PMBSBikeImgContainer">
                  <StyledImgBox
                    image={{
                      src: item.image.large_url,
                      alt: `${item.name} Rentals`,
                    }}
                    imgHeight="100%"
                    objectFit="contain"
                  />
                </Box>
                <Box className="PMBSBikeInfo">
                  <Typography700
                    component="p"
                    variant="h3"
                    className="PMBSModelName">
                    {item.name}
                  </Typography700>
                </Box>
              </NavigatorLink>

              <Box className="PMBSBikeInfo">
                {item.vehicle_models.map((vehicleModel) => (
                  <Box className="PMBSLinkContainer" key={vehicleModel.id}>
                    <NavigatorLink
                      href={PATHS.geoSlug([item.slug, vehicleModel.slug], {
                        ref: 'homepage_vehicles',
                      })}
                      translate={false}>
                      <Box className="PMBSLink">
                        <Typography500
                          component="span"
                          variant="h6"
                          className="PMBSLinkText">
                          {vehicleModel.name}
                        </Typography500>
                        <ChevronRightIcon className="PMBSLinkIcon" />
                      </Box>
                    </NavigatorLink>
                  </Box>
                ))}
                <Box className="PMBSLinkContainer">
                  <NavigatorLink
                    href={PATHS.geoSlug([item.slug], {
                      ref: 'homepage_vehicles',
                    })}
                    translate={false}>
                    <Box className="PMBSLink">
                      <Typography500
                        component="span"
                        variant="h6"
                        className="PMBSLinkText">
                        {t('fe_er_home_page:all_models', {
                          vehicle_makes: item.name,
                        })}
                      </Typography500>
                      <ChevronRightIcon className="PMBSLinkIcon" />
                    </Box>
                  </NavigatorLink>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </StyledSliderSectionContiner>
    </StyledBox>
  ) : (
    <></>
  );
};

export { PopularMotorcycleBrandsSection };
