import { styled } from '@mui/material/styles';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import { ImgBox } from 'views/common/components';

const StyledNavigatorLink = styled(NavigatorLink)(({ theme }) => ({
  '& .DCContainer': {
    color: theme.palette.common.white,
    position: 'relative',

    '& .CDTextContainer': {
      position: 'absolute',
      left: theme.spacing(2),
      top: '50%',
      transform: 'translateY(-50%)',
      '& .DCSubPromo': {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(16),
        [theme.breakpoints.up('lg')]: {
          marginBottom: theme.spacing(1),
        },
      },
    },
    '& .DCDesc': {
      margin: theme.spacing(1, 0),
      [theme.breakpoints.down('lg')]: {
        fontSize: theme.spacing(1.5),
        lineHeight: theme.spacing(2),
        fontWeight: 400,
      },
    },
    '& .DCDealValue': {
      lineHeight: theme.spacing(6),
      letterSpacing: -0.562,
      [theme.breakpoints.down('lg')]: {
        fontSize: theme.spacing(3.5),
        lineHeight: theme.spacing(4),
      },
      '& .day': {
        fontSize: theme.spacing(4),
        [theme.breakpoints.down('lg')]: {
          fontSize: theme.spacing(2.25),
          lineHeight: theme.spacing(4),
        },
      },
    },
    '& .DCViewBtn': {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      backgroundColor: theme.palette.secondary.dark,
      [theme.breakpoints.down('lg')]: {
        fontSize: theme.spacing(1.25),
        lineHeight: theme.spacing(1.25),
      },
    },
  },
}));

export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  '&.IBimgbox': {
    zIndex: -1,
    [theme.breakpoints.down('lg')]: {
      height: theme.spacing(17),
    },
  },
}));

export default StyledNavigatorLink;
