import { SliderSectionContiner } from 'views/common/components';
import { styled } from '@mui/material/styles';

const StyledSliderSectionContiner = styled(SliderSectionContiner)(
  ({ theme }) => ({
    '& .chirldrenWrapper': {
      position: 'relative',
    },
    '& .CarouselItem, .RSCConatainer, .RSCFlexConatainer': {
      height: '100%',
    },

    '& .react-multi-carousel-list': {
      position: 'static',
      [theme.breakpoints.down('md')]: {
        position: 'relative',
      },
      '& .react-multiple-carousel__arrow ': {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: theme.spacing(6),
        height: theme.spacing(6),
        borderRadius: '50%',
        backgroundColor: 'rgba(48, 48, 48, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.common.white,
        cursor: 'pointer',
        [theme.breakpoints.down('lg')]: {
          display: 'none',
        },
        '&:before': {
          fontWeight: 'bold',
        },
        '&:hover': {
          backgroundColor: theme.palette.secondary.dark,
        },
        '&.react-multiple-carousel__arrow--left': {
          left: 'calc(4% + 1px)',
          [theme.breakpoints.up('md')]: {
            left: theme.spacing(-2),
          },
        },
        '&.react-multiple-carousel__arrow--right': {
          right: 'calc(4% + 1px)',
          [theme.breakpoints.up('md')]: {
            right: theme.spacing(-2),
          },
        },
      },
    },
    '& .SDContiner': {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      '& .SDAArrow.SDALeftArrow, & .SDAArrow.SDARightArrow': {
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      },
    },
  }),
);

export { StyledSliderSectionContiner };
