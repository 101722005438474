import React from 'react';
import {
  Container, Paper, Typography,
} from '@mui/material';
import AboutUsSectionStyledBox from 'views/HomeView/components/AboutUsSection/AboutUsSection.styles';
import Typography500 from 'views/common/components/UI/Typography500';
import { useTranslation } from 'next-i18next';
import ExpandableDescription from 'views/common/components/UI/ExpandableDescription';

const AboutUsSection = () => {
  const { t } = useTranslation('fe_er_home_page');

  return (
    <AboutUsSectionStyledBox>
      <Container maxWidth="xl">
        <Typography500 component="p" variant="h3" className="AUTitle">
          {t('fe_er_home_page:about_us')}
        </Typography500>
        <Paper elevation={0} className="AUTextSecction AUTextSecctionLg">
          <Typography variant="body1" component="p" className="AUText">{t('fe_er_home_page:about_us_text')}</Typography>
        </Paper>
        <Paper elevation={0} className="AUTextSecction AUTextSecctionMdAndSM">
          <ExpandableDescription
            description={t('fe_er_home_page:about_us_text')}
            collapsedSize={100}
   />
        </Paper>
      </Container>
    </AboutUsSectionStyledBox>

  );
};
export { AboutUsSection };
