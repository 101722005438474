import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: theme.palette.grey[800],
  textAlign: 'center',
  color: theme.palette.grey.A000,
  overflow: 'hidden',
}));
