import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Typography700 } from 'views/common/components';
import Typography500 from 'views/common/components/UI/Typography500/Typography500';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import useMultiSizedImage from 'views/common/hooks/useMutiSizeImage';

import StyledNavigatorLink, {
  StyledImgBox,
} from 'views/HomeView/components/PromotionCardsSection/components/PromotionCard/PromotionCard.styles';

const PromotionCard = (props) => {
  const { promotion } = props;
  const sizedImg = useMultiSizedImage(promotion.image);
  return (
    <StyledNavigatorLink href={promotion.actionHref}>
      <Box className="DCContainer">
        <Box className="CDTextContainer">
          <Typography className="DCSubPromo">
            {promotion.sub_promo_text}
          </Typography>
          <Typography700
            variant="h1"
            component="p"
            className="DCDealValue"
            dangerouslySetInnerHTML={{
              __html: promotion.promo_text,
            }}
          />
          <Typography500 variant="h5" component="p" className="DCDesc">
            {promotion.title}
          </Typography500>
          {promotion.actionLabel && (
            <>
              <Button
                component={NavigatorLink}
                href={promotion.actionHref}
                className="DCViewBtn"
                variant="contained">
                {promotion.actionLabel}
              </Button>
            </>
          )}
        </Box>

        <StyledImgBox
          image={{
            src: sizedImg,
            alt: promotion.title,
          }}
          imgHeight="201px"
          imgWidth="100%"
          priority
          imgObjectFit="cover"
        />
      </Box>
    </StyledNavigatorLink>
  );
};

export { PromotionCard };
