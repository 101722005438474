import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const AboutUsSectionStyledBox = styled(Box)(({ theme }) => ({
  '& .AUTitle': {
    marginBottom: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
  '& .AUText': {
    color: theme.palette.grey[400],
  },
  '& .AUTextSecction': {
    backgroundColor: 'transparent',
    '&.AUTextSecctionLg': {
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    '&.AUTextSecctionMdAndSM': {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
  },
}));

export default AboutUsSectionStyledBox;
