import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { DestinationCard } from 'views/common/components';
import { useTranslation } from 'next-i18next';
import PATHS from 'lib/navigation/paths';
import { SLUGS_TYPES } from 'lib/utils/CONSTANTS';
import { StyledSliderSectionContiner } from 'views/HomeView/components/ContinueWhereULeftOff/ContinueWhereULeftOff.styles';

const FeaturedMotorcycleDestinationsSection = (props) => {
  const { destinationCards } = props;
  const { t } = useTranslation('fe_er_home_page');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const destinationCardLink = (item) => {
    if (item.entity) {
      if (item.entity.type === SLUGS_TYPES.location) {
        return PATHS.locationSlug(item.entity.slug);
      }
      return PATHS.tour.index(item.entity.tour_type.slug, item.entity.slug);
    }
    return item.url;
  };

  return (
    <StyledSliderSectionContiner
      title={t('fe_er_home_page:featured_motorcycle_destinations')}
      component="h2"
      buttons={[
        {
          text: t('fe_er_home_page:more_destinations'),
          path: PATHS.locations(),
        },
      ]}
      deskTopItems={3}
      carouselArrow={!isMobile}>
      {destinationCards.map((item) => (
        <Box className="CarouselItem" key={item.image_url}>
          <DestinationCard
            title={item.title || item.entity?.name}
            image={{
              src: item.image_url,
              alt: item.title || item.entity?.name,
            }}
            link={destinationCardLink(item)}
          />
        </Box>
      ))}
    </StyledSliderSectionContiner>
  );
};
export { FeaturedMotorcycleDestinationsSection };
