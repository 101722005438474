import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MainWidgetContainerSection } from 'views/common/components';

const StyledBox = styled(Box)(() => ({
  overflowX: 'hidden',
}));
export const StyledMainWidgetContainerSection = styled(
  MainWidgetContainerSection,
)(({ theme }) => ({
  '&.MWCSContainer': {
    '& .MWCSImg': {
      objectPosition: 'right bottom',
    },
  },
  [theme.breakpoints.up('xxl')]: {
    '&.MWCSContainer': {
      minHeight: theme.spacing(71.875),
      '& .MWCSWidetWrapperContainer': {
        paddingTop: theme.spacing(12),
      },
    },
  },
}));

export default StyledBox;
