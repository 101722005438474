import React from 'react';
import {
  Box,
  Grid,
  styled,
  Container,
  Skeleton,
  useTheme,
  useMediaQuery,
  Divider,
} from '@mui/material';
import { DestinationCardSkeleton } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .FMDSSectionWrapper': {
    padding: theme.spacing(4, 0),
    display: 'flex',
    '& .FMDSBtn': {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
        order: 1,
      },
    },
    '& .FMDSTitle': {
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },
}));
const FeaturedMotorcycleDestinationsSectionSkeleton = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const getnumber = () => {
    let n;
    if (isTablet && !isMobile) {
      n = 2;
    } else if (isTablet && isMobile) {
      n = 1;
    } else {
      n = 3;
    }
    return n;
  };

  return (
    <StyledBox>
      <Container maxWidth="xl">
        <Box className="FMDSSectionWrapper">
          <Grid container rowSpacing={2} columnSpacing={{ xs: 3 }}>
            <Grid item xs={12} lg={6} className="FMDSTitle">
              <Skeleton variant="rectangular" width="40%" height={40} />
            </Grid>
            <Grid item xs={12} lg={6} className="FMDSBtn">
              <Skeleton variant="rectangular" width="40%" height={40} />
            </Grid>
            {[...Array(getnumber())].map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item xs={12} md={6} lg={4} key={index}>
                <DestinationCardSkeleton />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Divider />
      </Container>
    </StyledBox>
  );
};
export { FeaturedMotorcycleDestinationsSectionSkeleton };
