import { React, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { Box, useTheme, useMediaQuery } from '@mui/material';

import PATHS from 'lib/navigation/paths';
import { StyledSliderSectionContiner } from 'views/HomeView/components/ContinueWhereULeftOff/ContinueWhereULeftOff.styles';
import { PromotionCard } from 'views/HomeView/components/PromotionCardsSection/components';

const PromotionCardsSection = (props) => {
  const { promotions } = props;
  const { t } = useTranslation('fe_er_home_page');
  const calculateActionAttributes = (promotion) => {
    switch (promotion.type) {
      case 'Deal':
        return {
          actionHref: PATHS.motorcycleDeals.slug(promotion.slug, {
            ref: 'homepage_deals',
          }),
          actionLabel: t('fe_er_home_page:view_deal'),
        };
      case 'Membership':
        return {
          actionHref: PATHS.motorcycleClub.index({
            ref: 'homepage_deals',
          }),
          actionLabel: t('fe_er_home_page:learn_more'),
        };
      case 'BlogPost':
        return {
          actionHref: PATHS.motorcycleTouring.slug(promotion.slug, {
            ref: 'homepage_deals',
          }),
          actionLabel: t('fe_er_home_page:learn_more'),
        };
      default:
        return {
          actionHref: promotion.slug || '',
          actionLabel: null,
        };
    }
  };
  const visiblePromotions = useMemo(
    () => promotions.map((promotion) => ({
      ...promotion,
      ...calculateActionAttributes(promotion),
    })),
    [promotions],
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledSliderSectionContiner
      component="h2"
      title={t('fe_er_home_page:motorcycle_offers')}
      buttons={[
        {
          text: t('fe_er_home_page:more_deals'),
          path: PATHS.motorcycleDeals.index(),
        },
      ]}
      deskTopItems={3}
      carouselArrow={!isMobile}>
      {visiblePromotions.map((promotion) => (
        <Box className="CarouselItem" key={promotion.id}>
          <PromotionCard promotion={promotion} />
        </Box>
      ))}
    </StyledSliderSectionContiner>
  );
};
export { PromotionCardsSection };
